export function formatPostcode(postcode) {
    const lastMatch = '[0-9][a-zA-Z][a-zA-Z]';
    const match = postcode.match(lastMatch);
    const hasSpace = postcode.indexOf(' ') >= 2;

    if (match !== null && !hasSpace) {
        return postcode.replace(/^(.*)(.{3})$/, '$1 $2');
    } else {
        return postcode;
    }
}
