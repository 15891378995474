import { useState } from 'react';
import PropTypes from 'prop-types';

import {
    StyledTooltipContainer,
    StyledTooltipButtonContainer,
    StyledTooltipButton,
    StyledTooltipContentContainer,
    StyledTooltipContent,
} from './Tooltip.styled';

/**
 * A simple visibility toggle that displays informational text.
 */
const Tooltip = ({ checked, children }) => {
    const [isActive, setActive] = useState(checked);

    return (
        <StyledTooltipContainer active={isActive}>
            <StyledTooltipButtonContainer>
                <StyledTooltipButton
                    onClick={(e) => {
                        e.preventDefault();
                        setActive(!isActive);
                    }}
                    tabIndex="0"
                >
                    ?
                </StyledTooltipButton>
            </StyledTooltipButtonContainer>
            {isActive && (
                <StyledTooltipContentContainer>
                    <StyledTooltipContent>{children}</StyledTooltipContent>
                </StyledTooltipContentContainer>
            )}
        </StyledTooltipContainer>
    );
};

Tooltip.defaultProps = {
    checked: false,
};

Tooltip.propTypes = {
    checked: PropTypes.bool,
};

export default Tooltip;
