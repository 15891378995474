import styled, { css } from 'styled-components';
import { VARIANTS } from '../consts';

const getPrefixStyles = ({ theme, variant }) =>
    theme.input[variant || theme.input[VARIANTS.PRIMARY]].prefix;

export const StyledPrefix = styled.span`
    ${({ theme, variant }) => css`
        position: absolute;
        top: 2.1rem;
        height: 4.5rem;
        padding: 0 0.8rem 0 0;
        font-size: 2.2rem;
        font-weight: 300;
        color: ${() => getPrefixStyles({ theme, variant }).color};
        background-color: ${() =>
            getPrefixStyles({ theme, variant }).backgroundColor};
        transition: padding-top 0.1s linear;
    `}
`;
