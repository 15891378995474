import styled, { css } from 'styled-components';
import { VARIANTS } from '../consts';

const getInputStyles = ({ theme, variant }) =>
    theme.input[variant || VARIANTS.PRIMARY];

export const StyledInput = styled.input`
    ${({
        theme,
        variant = VARIANTS.PRIMARY,
        hideLabel,
        hasPrefix,
        isValid,
        isError,
        isPopulated,
        center,
        disabled,
    }) => {
        const chosenVariant = getInputStyles({ theme, variant });
        return css`
            width: 100%;
            padding: 2rem 0 0.5rem;
            margin-bottom: 0;
            font-size: 2.2rem;
            font-weight: ${chosenVariant?.fontWeight || '300'};
            color: ${chosenVariant?.color};
            background-color: ${chosenVariant.backgroundColor};
            border-radius: 0;
            box-shadow: ${chosenVariant.boxShadow};

            /* Add specificity with attr selector */
            &[type],
            &[type='text'] {
                border-top: none;
                border-right: none;
                border-bottom: ${chosenVariant.borderBottom};
                border-left: none;
            }

            transition: all 0.1s linear;
            appearance: none;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-text-fill-color: ${chosenVariant.color};
                background-color: ${chosenVariant.backgroundColor};
                box-shadow: 0 0 0 50px ${chosenVariant.backgroundColor};
                transition: background-color 5000s ease-in-out 0s;
            }

            &:focus {
                border-bottom: ${chosenVariant.focus.borderBottom};
                outline: none;

                &::placeholder {
                    color: ${chosenVariant.placeholder.color};
                }
            }

            &::placeholder {
                color: ${chosenVariant.placeholder.color};
                font-size: ${chosenVariant.placeholder.size};
                font-style: ${chosenVariant.placeholder.style};
                line-height: normal;
            }

            ${center &&
            css`
                text-align: center;
            `}

            ${hideLabel &&
            css`
                padding-top: 0;
            `}

            ${hasPrefix &&
            css`
                width: calc(100% - 3rem);
                margin-left: 3rem;
            `}

            ${isValid &&
            isPopulated &&
            css`
                &[type],
                &[type='text'] {
                    border-bottom: 0.2rem solid
                        ${chosenVariant.valid.borderBottomColour ||
                        theme.colour.action.success};
                }
            `}

            ${isError &&
            css`
                &[type],
                &[type='text'] {
                    border-bottom: 0.2rem solid ${theme.colour.action.error};
                }
            `}

            ${isPopulated &&
            css`
                text-indent: 0;
            `}
            

            ${disabled &&
            css`
                color: ${theme.colour.grey.base};

                &[type],
                &[type='text'] {
                    border-bottom: 0.2rem solid ${theme.colour.grey.base};
                }
            `}
        `;
    }}
`;
