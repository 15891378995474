import { string } from 'prop-types';
import { Field } from 'formik';
import postcodeValid from '../../../../validators/postcode/postcode';
import { VARIANTS } from '../../../reduxform/consts';
import { FormikFormInput } from '..';
import { formatPostcode } from '../../../../../formatters';

const validator = (value) =>
    postcodeValid(value) ? undefined : 'Please enter a valid postcode';

const FormikPostcodeInput = ({ name, label, variant, ...rest }) => {
    const handleChange = (postcode) => formatPostcode(postcode.toUpperCase());

    return (
        <Field
            component={FormikFormInput}
            name={name}
            label={label}
            type="text"
            variant={variant}
            validate={validator}
            onChange={handleChange}
            {...rest}
        />
    );
};

FormikPostcodeInput.defaultProps = {
    variant: VARIANTS.PRIMARY,
    name: 'postcode',
    label: 'Postcode',
};

FormikPostcodeInput.propTypes = {
    variant: string,
    name: string,
    label: string,
};

export default FormikPostcodeInput;
