import { format, isDate } from 'date-fns';
import 'react-day-picker/lib/style.css';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import ErrorText from '../../ErrorText/ErrorText';
import FormInput from '../../reduxform/FormInput/FormInput';
import InputFile from '../../InputFile';
import CheckBox from '../../Checkbox/CheckBox';
import DropDown from '../../DropDown/DropDown';
import MultiSelectButton from '../../MultiSelectButton/MultiSelectButton';
import DayPickerInput from '../pure/DayPickerInput';
import Radio from '../../reduxform/Radio/Radio';
import { variants } from '../../reduxform/DropDown/DropDown.styled';

export const FormikFormInput = ({ form, field, onChange, ...props }) => {
    const error = getIn(form.errors, field.name);
    const touch = getIn(form.touched, field.name);
    const value = getIn(form.values, field.name);
    const showError = Boolean(touch && error);
    const handleChange = (e) => {
        form.setFieldValue(field.name, onChange(e.target.value));
    };

    const optionalProps = {};
    if (onChange) {
        optionalProps.onChange = handleChange;
    }
    return (
        <FormInput
            showError={showError}
            isValid={!!value && typeof error === 'undefined'}
            error={error}
            input={field}
            {...optionalProps}
            {...props}
        />
    );
};

export const FormikCheckBox = ({
    form,
    field,
    handleChange,
    withErrorMessage,
    ...props
}) => {
    const value = getIn(form.values, field.name);
    const onChange = (checked) => {
        if (typeof handleChange === 'function') {
            handleChange(checked);
        }
        form.setFieldValue(field.name, checked);
    };
    const touch = getIn(form.touched, field.name);
    const error = getIn(form.errors, field.name);
    const showError = withErrorMessage && Boolean(touch && error);
    return (
        <>
            <CheckBox
                id={field.name}
                checked={!!value}
                onChange={onChange}
                {...props}
            />
            {showError && <ErrorText>{error}</ErrorText>}
        </>
    );
};

FormikCheckBox.propTypes = {
    /** Passed in from Field component */
    form: PropTypes.object.isRequired,
    /** Passed in from Field component */
    field: PropTypes.object.isRequired,
    label: PropTypes.string,
    tooltipText: PropTypes.string,
    handleChange: PropTypes.func,
};

export const FormikRadio = ({ form, field, options, disabled }) => {
    let value = getIn(form.values, field.name);

    const handleChange = (e) => {
        value = e.target.value;

        // Radio only accepts strings so cast field value to boolean
        if (value === 'false') {
            value = false;
        }

        if (value === 'true') {
            value = true;
        }

        form.setFieldValue(field.name, value);
    };

    return (
        <Radio
            input={field}
            meta={form}
            options={options}
            initialValue={value}
            disabled={disabled}
            handleChange={handleChange}
        />
    );
};

FormikRadio.propTypes = {
    /** Passed in from Field component */
    form: PropTypes.object.isRequired,
    /** Passed in from Field component */
    field: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
};

export const FormikMultiSelect = ({
    form,
    field,
    options,
    label,
    ...props
}) => {
    const value = getIn(form.values, field.name);
    const input = {
        onChange: () => ({}),
    };

    const handleChange = (e) => {
        // MultiSelectButton casts booleans to strings so correct this
        // undesirable behaviour here
        let value = e.target.getAttribute('data-value');
        if (value === 'false') {
            value = false;
        }
        if (value === 'true') {
            value = true;
        }
        form.setFieldValue(field.name, value);
    };

    return (
        <>
            {label && (
                <label className="block mb-2" htmlFor={field.name}>
                    {label}
                </label>
            )}
            <MultiSelectButton
                input={input}
                handleChange={handleChange}
                values={options}
                chosen={value}
                {...props}
            />
        </>
    );
};

// eslint-disable-next-line
const validateDate = (date) => /^\d{4}\-\d{2}\-\d{2}$/.test(date);

FormikMultiSelect.propTypes = {
    /** Passed in from Field component */
    form: PropTypes.object.isRequired,
    /** Passed in from Field component */
    field: PropTypes.object.isRequired,
};

// Wrapper around React day picker that allows it to be used with
// Formik.Field
// If a valid date is selected, the change callback is called
// with the date formatted as a datestring in the YYYY-MM-DD format
// If an invalid date is selected (i.e manually entered incorrectly),
// selectedDay is undefined
export const FormikDayPickerInput = ({
    form,
    field,
    handleChange,
    ...props
}) => {
    const handleDayChange = (selectedDay) => {
        if (typeof handleChange === 'function') {
            // If handleChange is called with undefined
            // The following error arises https://github.com/formium/formik/issues/187
            // Hence we call it with an empty string
            if (isDate(selectedDay)) {
                selectedDay = format(selectedDay, 'yyyy-MM-dd');
            }
            handleChange(selectedDay || '');
        }
    };

    const onDayClick = (selectedDay) => {
        // If valid date entered, React Day Picker will return a
        // date object
        if (isDate(selectedDay)) {
            selectedDay = format(selectedDay, 'yyyy-MM-dd');
        }
        form.setFieldValue(field.name, selectedDay);
    };
    const touch = getIn(form.touched, field.name);
    const error = getIn(form.errors, field.name);
    const showError = Boolean(touch && error);

    let value = getIn(form.values, field.name);
    // The format of the date is transformed for the UI
    if (validateDate(value)) {
        value = format(new Date(value), 'dd/MM/yyyy');
    }
    return (
        <DayPickerInput
            onDayChange={handleDayChange}
            onDayClick={onDayClick}
            value={value}
            error={showError ? error : ''}
            {...props}
        />
    );
};

FormikDayPickerInput.propTypes = {
    /** Passed in from Field component */
    form: PropTypes.object.isRequired,
    /** Passed in from Field component */
    field: PropTypes.object.isRequired,
    handleChange: PropTypes.func,
};

export const FormikDropDown = ({
    form,
    field,
    labelText,
    variant,
    options,
    ...props
}) => {
    const touch = getIn(form.touched, field.name);
    const error = getIn(form.errors, field.name);
    const showError = Boolean(touch && error);
    const value = getIn(form.values, field.name);

    return (
        <DropDown
            showLabelText={true}
            labelText={labelText}
            variant={variant}
            name={field.name}
            options={options}
            savedValue={value}
            onSelect={(value) => {
                form.setFieldValue(field.name, value);
            }}
            showError={showError}
            error={showError ? error : ''}
            {...props}
        />
    );
};

FormikDropDown.defaultProps = {
    variant: variants.PRIMARY,
};

FormikDropDown.propTypes = {
    /** Passed in from Field component */
    form: PropTypes.object.isRequired,
    /** Passed in from Field component */
    field: PropTypes.object.isRequired,
    variant: PropTypes.oneOf([
        variants.PRIMARY,
        variants.SECONDARY,
        variants.TERTIARY,
    ]),
};

export const FormikInputFile = ({ form, field, ...props }) => {
    const value = getIn(form.values, field.name);
    const touch = getIn(form.touched, field.name);
    const error = getIn(form.errors, field.name);
    const showError = Boolean(touch && error);

    const onChange = (files) => {
        form.setFieldValue(field.name, files);
    };

    return (
        <InputFile
            value={value}
            onChange={onChange}
            error={error}
            showError={showError}
            {...props}
        />
    );
};
