import styled from 'styled-components';
import { pageMaxWidth } from '../../constants';
export const CardsWrapper = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: ${pageMaxWidth};
    gap: ${({ coralTheme: { spacing } }) => spacing.sm};

    > * {
        width: 28.4rem;
        max-width: 100%;
    }
`;
export const TitleWrap = styled.div `
    width: 100%;
    max-width: 27rem;

    margin: 0 auto 7.8rem;
    text-align: center;

    @media (min-width: 500px) {
        max-width: 66rem;
    }

    > h2 {
        font-size: 2.2rem;

        @media (min-width: 500px) {
            font-size: 3.6rem;
        }
    }
`;
export const CardContent = styled.div `
    margin-bottom: 4.2rem;

    h4 {
        margin-bottom: 1.8rem;
    }
`;
