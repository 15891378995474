// *** replacement for @babel/polyfill https://babeljs.io/docs/en/babel-polyfill
import 'core-js';
import 'regenerator-runtime/runtime';
// *** //
import { initClient } from 'octo-quote/services';
import introspectionQueryResultData from '../fragmentTypes.json';
const getAppEnv = () => {
    // Grab data and settings from the script tag on quote.html
    const scriptId = 'referral-init-script';
    const ScriptEl = document.getElementById(scriptId);
    const rootId = (ScriptEl === null || ScriptEl === void 0 ? void 0 : ScriptEl.dataset.rootId) || '';
    // grab data from the django context
    const apiUrl = (ScriptEl === null || ScriptEl === void 0 ? void 0 : ScriptEl.dataset['apiUrl']) || '';
    const productCode = (ScriptEl === null || ScriptEl === void 0 ? void 0 : ScriptEl.dataset['productCode']) || '';
    const referralCode = (ScriptEl === null || ScriptEl === void 0 ? void 0 : ScriptEl.dataset['referralCode']) || '';
    const graphqlClientSettings = {
        baseUrl: apiUrl,
        introspectionQueryResultData,
    };
    const graphqlClient = initClient(graphqlClientSettings);
    // graphqlClientSettings is used for the integration test
    return {
        rootId,
        apiUrl,
        productCode,
        referralCode,
        graphqlClient,
        graphqlClientSettings,
    };
};
export default getAppEnv;
