import styled from 'styled-components';
import { pageMaxWidth } from '../../constants';
export const TitleWrapper = styled.div `
    margin-bottom: 6.4rem;
    text-align: center;

    > h2 {
        font-size: 2.2rem;

        @media (min-width: 500px) {
            font-size: 3.6rem;
        }
    }
`;
export const InnerWrapper = styled.ol `
    max-width: ${pageMaxWidth};
    width: 100%;
    margin: 0 auto;

    counter-reset: switching-tariff-counter;
    text-align: center;

    @media (min-width: 900px) {
        display: grid;
        justify-content: space-evenly;
        grid-template-columns: 25% 25% 25% 25%;
    }
`;
export const Card = styled.li `
    display: grid;
    grid-template-rows: 11rem 14rem;
    justify-items: center;
    height: 100%;

    width: 16rem;
    max-width: 100%;
    margin: auto;
    margin-bottom: 6.4rem;

    text-align: center;
    list-style: none;

    counter-increment: switching-tariff-counter;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 900px) {
        margin-bottom: 0;
    }

    &:before {
        content: counter(switching-tariff-counter);
        display: block;
        margin-bottom: 3.2rem;

        font-size: 6.4rem;
        font-weight: 400;
        line-height: 1;
    }
`;
export const CardTitle = styled.p `
    margin-top: 1.6rem;
`;
