import PropTypes from 'prop-types';
import { VARIANTS } from '../consts';

import FormInputMarkup from './FormInputMarkup';
import FormInputWithTooltip from './FormInputWithTooltip';
import { StyledInput } from './FormInput.styled';
import { getInputClass, getContainerClass, getPrefixClass } from './helpers';

const FormInput = ({
    input,
    showError,
    isValid,
    secure,
    hideLabel,
    index,
    label,
    prefix,
    helpText,
    disabled,
    error,
    type,
    tooltipText,
    maxLength,
    min,
    max,
    autoComplete,
    placeholder,
    asyncValidating,
    showErrorIcon,
    variant,
    onChange,
    showLabel,
    useMiniHelpText,
    step,
}) => {
    const inputClass = getInputClass({
        value: input.value,
        showError,
        isValid,
        secure,
        variant,
    });
    const containerClass = getContainerClass({ value: input.value, hideLabel });
    const prefixClass = getPrefixClass({ value: input.value });
    let labelId = `label-${label.replace(/ /g, '-')}`;
    if (index) {
        labelId += `-${index}`;
    }

    const inputType = type || 'text';
    const errorHtml = showError && !isValid ? error : null;

    // Prevent the following attributes being applied to the underlying input element if they're not needed
    const optionalProps = {};
    if (maxLength) {
        optionalProps.maxLength = maxLength;
    }
    if (min) {
        optionalProps.min = min;
    }
    if (max) {
        optionalProps.max = max;
    }
    if (autoComplete) {
        optionalProps.autoComplete = autoComplete;
    }
    if (onChange) {
        optionalProps.onChange = onChange;
    }
    if (disabled) {
        optionalProps.disabled = disabled;
    }

    const inputEl = (
        <StyledInput
            {...input}
            {...optionalProps}
            className={inputClass}
            type={inputType}
            id={labelId}
            placeholder={placeholder || label}
            isValid={isValid}
            isPopulated={!!input.value}
            isError={!!errorHtml}
            hasPrefix={!!prefix}
            hideLabel={hideLabel}
            hasTooltip={!!tooltipText}
            variant={variant}
            step={step}
        />
    );

    const inputMarkup = (
        <FormInputMarkup
            disabled={disabled}
            containerClass={containerClass}
            prefix={prefix}
            prefixClass={prefixClass}
            labelId={labelId}
            label={label}
            inputEl={inputEl}
            errorHtml={errorHtml}
            helpText={helpText}
            isPopulated={showLabel || !!input.value}
            hasTooltip={!!tooltipText}
            variant={variant}
            asyncValidating={asyncValidating}
            showErrorIcon={showErrorIcon}
            useMiniHelpText={useMiniHelpText}
        />
    );

    if (tooltipText) {
        return (
            <FormInputWithTooltip
                inputEl={inputMarkup}
                tooltipText={tooltipText}
            />
        );
    }

    return inputMarkup;
};
export default FormInput;

FormInput.defaultProps = {
    variant: VARIANTS.PRIMARY,
};

FormInput.propTypes = {
    // Redux Form type.
    input: PropTypes.object,
    // The inputs label
    label: PropTypes.string,
    // Defines the 'type' attribute on the input. Defaults to 'text'
    type: PropTypes.string,
    // Help text provided to the user
    helpText: PropTypes.string,
    // Saved content for an input that has been previously filled in
    isValid: PropTypes.bool,
    // has component been touched.
    touched: PropTypes.bool,
    // A function called when input content is changed
    error: PropTypes.string,
    // A keyboard pattern provided to override the default keyboard on mobile
    pattern: PropTypes.string,
    // Content displayed before the input (for example '07' before a mobile number)
    prefix: PropTypes.string,
    // A boolean to determine whether to show an error
    showError: PropTypes.bool,
    // Defines the index for the input, used if multiples are generated to keep ID's unique
    disabled: PropTypes.bool,
    // Defines if the input is disabled
    index: PropTypes.number,
    // Specifies if the input should have hide it's corresponding label
    hideLabel: PropTypes.bool,
    // Defines the text shown in the input's accompanying tooltip
    tooltipText: PropTypes.string,
    // Prevents Inspectlet from recording the value of the field
    secure: PropTypes.bool,
    // Autocomplete input property space delimited string
    autoComplete: PropTypes.string,
    // Placeholder string for input
    placeholder: PropTypes.string,
    variant: PropTypes.string,
};
