import styled, { keyframes } from 'styled-components';
import { lighten } from 'polished';

const tooltipFocus = ({ theme }) => keyframes`
    0% {
        background-color: ${theme.tooltip.backgroundColor};
    }

    20% {
        background-color: ${lighten(0.1, theme.tooltip.backgroundColor)};
    }

    90% {
        background-color: ${theme.tooltip.backgroundColor};
    }

    100% {
        background-color: ${theme.tooltip.backgroundColor};
    }
`;
export const StyledTooltipContainer = styled.div`
    margin-top: -1.1rem;
    margin-bottom: ${(props) => (props.active ? '3rem' : '0')};
`;

export const StyledTooltipButtonContainer = styled.div`
    position: relative;
    display: inline-block;
    float: right;
    width: 15%;
    margin-top: -6.5rem;
`;

export const StyledTooltipButton = styled.button`
    width: 100%;
    padding: 1.3rem 0 1.4rem;
    font-size: 2.8rem;
    color: white;
    text-align: center;
    background-color: ${(props) => props.theme.tooltip.backgroundColor};
    border: none;
    outline: none;
    transition: background-color 0.2s linear;
    appearance: none;

    &:focus {
        background-color: ${(props) =>
            lighten(0.1, props.theme.tooltip.backgroundColor)};
        outline: none;
        animation: ${tooltipFocus} 3s infinite;
    }

    &:hover {
        background-color: ${(props) =>
            lighten(0.1, props.theme.tooltip.backgroundColor)};
    }
`;

export const StyledTooltipContentContainer = styled.div`
    position: relative;
    display: block;

    &:before {
        position: absolute;
        top: -1.7rem;
        right: 0;
        display: block;
        width: 15%;
        height: 1.7rem;
        background-color: transparent;
        content: '';
    }
`;

export const StyledTooltipContent = styled.div`
    padding: 2.4rem;
    color: white;
    text-align: left;
    background-color: ${(props) => props.theme.tooltip.backgroundColor};

    ul {
        color: white;
    }
`;
