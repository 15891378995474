const SVGArrowDownIcon = ({ fill = 'currentcolor' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41">
        <title>An arrow pointing downwards</title>
        <desc>An arrow pointing downwards</desc>
        <path
            fill={fill}
            d="M20.5 29L9.7 18.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l8.7 8.7 8.7-8.7c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1L20.5 29z"
        />
    </svg>
);

export default SVGArrowDownIcon;
