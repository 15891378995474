import styled, { css } from 'styled-components';

export const variants = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
};

const getDropDownStyles = ({ theme, variant }) =>
    theme.dropdown[variant] || theme.dropdown[variants.PRIMARY];

export const StyledDropDownContainer = styled.div`
    position: relative;
    margin: 2rem 0;

    ${(props) =>
        props.isDisabled &&
        css`
            opacity: 0.4;
            pointer-events: none;
        `};
`;

export const StyledDropDownIcon = styled.div`
    ${({ theme, variant }) => {
        const chosenVariant = getDropDownStyles({ theme, variant });
        return css`
            position: absolute;
            top: ${chosenVariant.icon.top};
            right: ${chosenVariant.icon.right};
            height: ${chosenVariant.icon.height};
            width: ${chosenVariant.icon.width};
            margin-top: -2rem;
            font-size: 1rem;
            line-height: 1rem;
            color: ${chosenVariant.color};
            pointer-events: none;
            background-color: ${chosenVariant.backgroundColor};
        `;
    }}
`;

export const StyledDropDownSelect = styled.select`
    ${({ theme, variant, isValid, isPopulated, showError, error }) => {
        const chosenVariant = getDropDownStyles({ theme, variant });
        return css`
            position: relative;
            width: 100%;
            padding: 2rem 0 0.5rem;
            font-size: 2.2rem;
            font-weight: 300;
            color: ${chosenVariant.color};
            background-color: ${chosenVariant.backgroundColor};
            border: 0;
            border-bottom: ${chosenVariant.borderBottom};
            border-radius: 0;
            outline: none;
            appearance: none;
            transition: all 0.2s ease-out;

            &:focus {
                border-bottom: ${chosenVariant.focus.borderBottom};
            }

            ${isValid &&
            isPopulated &&
            css`
                border-bottom: 0.2rem solid ${theme.colour.action.success};
            `}

            ${showError &&
            error &&
            css`
                border-bottom: 0.2rem solid ${theme.colour.action.error};
            `}
        `;
    }}
`;

export const StyledDropDownLabel = styled.label`
    ${({ theme, variant }) => css`
        position: absolute;
        top: ${getDropDownStyles({ theme, variant }).label.top};
        display: block;
        float: left;
        width: 100%;
        padding: 0;
        margin: 0 auto 0.4rem;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1rem;
        letter-spacing: 0.05rem;
        color: ${theme.colour.text.primary};
        text-align: left;
        pointer-events: none;
        opacity: ${(props) => (props.isPopulated ? '1' : '0')};
        transition: all 0.2s ease-out;
        z-index: 1;
    `}
`;

export const StyledDropDownError = styled.div`
    position: relative;
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.colour.action.error};
`;
