import { Form } from 'formik';
import styled from 'styled-components';
export const Wrapper = styled(Form) `
    display: flex;
    justify-content: ${({ $centered }) => $centered ? 'center' : 'flex-start'};
`;
// While Coral doesn't provide a postcode input fitting to this scenario,
// we hack into the old one and add some styling.
// TODO: Remove tweak once fixed in Coral library
export const InputWrapper = styled.span `
    display: inline-block;
    vertical-align: top;

    label {
        display: none;
    }

    input {
        height: 6rem;
        padding: 1.25rem 0 1.25rem 1.8rem;
        box-sizing: border-box;

        border-bottom: 0.2rem solid transparent !important;
        border-top-left-radius: 1.2rem;
        border-bottom-left-radius: 1.2rem;
        box-shadow: none !important;

        font-size: 1.4rem;

        :focus,
        :active {
            outline-style: auto;
            outline-offset: -3px;
            outline-color: ${({ theme }) => theme.colour.accent.primary};
        }

        @media (min-width: 500px) {
            font-size: 2.2rem;
        }
    }

    div {
        margin: 0;

        div {
            margin-top: 0.4rem;
            padding-left: 1.8rem;
        }
    }
`;
export const ButtonWrapper = styled.span `
    vertical-align: top;

    > button {
        height: 60px;
        min-width: 15.6rem;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        font-size: 1.4rem;

        @media (min-width: 500px) {
            font-size: 1.8rem;
        }
    }
`;
