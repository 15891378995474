import styled, { css } from 'styled-components';

export const StyledHelpText = styled.p`
    display: block;
    margin: 0.8rem 0 0;
    font-weight: 300;
    line-height: 1.8rem;
    ${({ theme, useMiniHelpText }) =>
        useMiniHelpText &&
        css`
            color: ${() => theme.helpText.color};
            font-style: ${() => theme.helpText.style};
            font-size: ${() => theme.helpText.size};
        `};
`;
