import Which2018 from './which-recommended-supplier-2018.svg';
import Which2019 from './which-recommended-supplier-2019.svg';
import Which2020 from './which-recommended-supplier-2020.svg';
import Which2021 from './which-recommended-supplier-2021.svg';
import Which2022 from './which-recommended-supplier-2022.svg';
import Which2023 from './which-recommended-supplier-2023.svg';
import Which2024 from './which-recommended-supplier-2024.svg';

const WhichLogos = [
    Which2018,
    Which2019,
    Which2020,
    Which2021,
    Which2022,
    Which2023,
    Which2024,
];

export default WhichLogos;
