import styled, { css } from 'styled-components';
export const TitleWrapper = styled.div `
    margin-bottom: 2.8rem;
`;
export const ParagraphyWrapper = styled.div `
    margin-bottom: 4.8rem;
`;
export const CardContainer = styled.div `
    width: 76rem;
    max-width: 100%;

    ${({ coralTheme: { breakpoints } }) => css `
        @media (min-width: ${breakpoints.sm / 16}em) {
            text-align: center;
        }
    `}
`;
export const CardContent = styled.div `
    ${({ coralTheme: { breakpoints, spacing } }) => css `
        @media (min-width: ${breakpoints.sm / 16}em) {
            padding: ${spacing.xxl};
        }
    `}
`;
