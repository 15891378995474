import PropTypes from 'prop-types';
import { StyledA } from './A.styled';

/**
 * @typedef { import("react").ReactNode } ReactNode
 */

/**
 * @typedef {Object} AProps
 * @prop {boolean} [block]
 * @prop {boolean} [dark]
 * @prop {boolean} [small]
 * @prop {string} [href]
 * @prop {string} [target]
 * @prop {string} [rel]
 * @prop {string} [title]
 * @prop {ReactNode | string} children
 */

/**
 * @param {AProps} Props
 */
const A = ({ children, block, dark, small, href, ...restProps }) => (
    <StyledA block={block} dark={dark} small={small} href={href} {...restProps}>
        {children}
    </StyledA>
);

A.defaultProps = {
    block: false,
    dark: false,
    small: false,
};

A.propTypes = {
    children: PropTypes.node.isRequired,
    block: PropTypes.bool,
    dark: PropTypes.bool,
    small: PropTypes.bool,
};

export default A;
