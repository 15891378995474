import styled, { css } from 'styled-components';

export const StyledA = styled.a`
    ${({ theme, block, dark, small }) => css`
        font-weight: 500;
        color: ${theme.colour.nav.link};
        text-decoration: underline;

        &:hover,
        &:focus {
            color: ${theme.colour.nav.linkHover};
            text-decoration: none;
            cursor: pointer;
        }

        &:focus {
            outline-style: auto;
            outline-offset: 3px;
            outline-color: ${theme.colour.focusRing};
        }

        ${dark &&
        css`
            color: ${theme.colour.nav.linkDark};

            &:hover,
            &:focus {
                color: ${theme.colour.nav.linkDarkHover};
            }
        `}

        ${block &&
        css`
            display: block;
            text-align: center;
        `}

        ${small &&
        css`
            font-size: ${theme.type.link.smallFontSize || '1.4rem'};
        `}
    `}
`;
