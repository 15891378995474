import styled from 'styled-components';
import { AwardedConstantine, Trustpilot } from '../Illustrations';
export const StyledAwardedConstantine = styled(AwardedConstantine) `
    padding: 0 1.8rem;

    @media (min-width: 500px) {
        padding: 0;
    }
`;
export const Content = styled.div `
    max-width: 48.2rem;
    align-self: center;

    @media (min-width: 777px) {
        margin-left: 4.4rem;
    }

    // To avoid wrapping spans with spans, it's better-ish
    // to target it using an evil descendant selector
    span {
        display: block;
        margin-top: 2.4rem;
    }
`;
export const LogosWrapper = styled.div `
    margin-top: 4.2rem;
    text-align: center;

    @media (min-width: 500px) {
        text-align: left;
    }
`;
export const StyledTrustpilot = styled(Trustpilot) `
    margin-top: 2.4rem;

    @media (min-width: 500px) {
        margin-left: 4.4rem;
        margin-top: 0;
    }
`;
