import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Formik } from 'formik';
import { FormikPostcodeInput } from 'octo-core/forms';
import { Button } from '@krakentech/coral';
import { postcodeValidator } from 'octo-core/validators';
import { Wrapper, InputWrapper, ButtonWrapper } from './Postcode.styled';
export const Postcode = ({ centered = false }) => (_jsx(Formik, { initialValues: { postcode: '' }, onSubmit: ({ postcode }) => {
        const url = `/quote/?postcode=${postcode.replace(' ', '')}`;
        window.location.href = url;
    }, validate: ({ postcode }) => postcodeValidator(postcode)
        ? {}
        : { postcode: 'Please enter a valid postcode' }, children: () => (_jsxs(Wrapper, { "$centered": centered, children: [_jsx(InputWrapper, { children: _jsx(FormikPostcodeInput, { name: "postcode", variant: "quaternary", label: "Your postal code" }) }), _jsx(ButtonWrapper, { children: _jsx(Button, { type: "submit", children: "Get a quote" }) })] })) }));
