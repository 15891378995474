export const variants = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    QUATERNARY: 'quaternary',
};

export const getInputStyles = ({ theme, variant }) =>
    theme.input[variant || theme.input[variants.PRIMARY]];

export const getInputClass = ({
    value,
    showError,
    isValid,
    secure,
    variant,
}) => {
    let inputClass = 'Input-default';

    if (value || showError) {
        inputClass = isValid ? 'Input-valid' : 'Input-invalid';
    }

    if (secure) {
        inputClass += ' inspectletIgnore';
    }

    // Stop CSS specificity overriding styled components
    if (
        variant !== 'primary' ||
        (inputClass !== 'Input-valid' && inputClass !== 'Input-invalid')
    ) {
        inputClass = null;
    }

    return inputClass;
};

export const getContainerClass = ({ value, hideLabel }) => {
    let containerClass = 'Input-unpopulated';

    if (value && value.length !== 0) {
        containerClass = 'Input-populated';
    }

    if (hideLabel) {
        containerClass += ' Input-noLabel';
    }

    return containerClass;
};

export const getPrefixClass = ({ value }) => {
    let prefixClass = 'Input-prefix';

    if (value && value.length !== 0) {
        prefixClass = 'Input-prefixActive';
    }

    return prefixClass;
};
