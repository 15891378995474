import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@krakentech/coral';
import { useWindowSize } from 'octo-core/hooks';
import { Postcode } from '../Postcode/Postcode';
import { Wrapper } from '../Wrapper';
import { ConfettiConstatine } from '../Illustrations/ConfettiConstantine';
import { Bold, CopyWrapper, ConstantineWrapper, WrapperInner, CtaWrapper, } from './Intro.styled';
export const Intro = () => {
    const { width } = useWindowSize();
    const shouldRenderConstantine = width > 900;
    return (_jsx(Wrapper, { lightBg: true, children: _jsxs(WrapperInner, { children: [_jsxs(CtaWrapper, { children: [_jsx(Typography, { variant: "h1", color: "tertiary", children: _jsx(Bold, { children: "You've been referred by a friend to Octopus Energy!" }) }), _jsx(CopyWrapper, { children: _jsx(Typography, { variant: "h4", children: "Sign-up here for your \u00A350 reward." }) }), _jsx(Postcode, {})] }), shouldRenderConstantine && (_jsx(ConstantineWrapper, { children: _jsx(ConfettiConstatine, {}) }))] }) }));
};
