import PropTypes from 'prop-types';
import { StyledPrefix } from './FormInputPrefix.styled';

const FormInputPrefix = ({ prefix, variant }) => (
    <StyledPrefix variant={variant}>{prefix}</StyledPrefix>
);

FormInputPrefix.propTypes = {
    prefix: PropTypes.string,
    variant: PropTypes.string,
};

export default FormInputPrefix;
