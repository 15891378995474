import { initGraphqlClient, generateGraphqlEndpoint } from 'octo-core/utils';

export const defaultFeatureFlags = {
    __typename: 'FeatureFlags',
    hasNewBankDetailsPage: false,
    hasInactivityMonitor: false,
    hasThirdPartyMarketingOptIn: false,
    hasSmets2InterestOptIn: false,
    hasPreviousBillingContactSection: true,
    hasNeedToKnowModal: false,
    hasBlockedAcquisitionJourney: false,
};

// The list below contains all feature flags and overrides available to the onboarding apps
export const defaultData = {
    support: {
        __typename: 'Support',
    },
    featureFlags: defaultFeatureFlags,
    overrides: {
        __typename: 'Overrides',
        thirdPartyMarketingOptInDescriptionId: '',
    },
};

// This is also used in the blueprint site to keep all sites consistent
export const getGraphqlData = ({
    support = {},
    featureFlags = {},
    overrides = {},
}) => ({
    support: {
        ...defaultData.support,
        ...support,
    },
    featureFlags: {
        ...defaultData.featureFlags,
        ...featureFlags,
    },
    overrides: {
        ...defaultData.overrides,
        ...overrides,
    },
});

export const initClient = ({
    baseUrl,
    introspectionQueryResultData,
    support = {},
    overrides = {},
    featureFlags = {},
}) =>
    initGraphqlClient({
        uri: generateGraphqlEndpoint(baseUrl),
        introspectionQueryResultData,
        data: getGraphqlData({ support, overrides, featureFlags }),
    });
