import styled from 'styled-components';
export const Content = styled.div `
    align-self: center;
    max-width: 40rem;

    @media (min-width: 777px) {
        margin-left: 4.4rem;
    }
`;
export const TitleWrapper = styled.div `
    margin-top: 4.4rem;
    margin-bottom: 2.4rem;

    > h2 {
        font-size: 2.2rem;
    }

    @media (min-width: 500px) {
        margin-top: 0;

        > h2 {
            font-size: 3.6rem;
        }
    }
`;
