import styled from 'styled-components';
import { pageMaxWidth } from '../../constants';
export const WrapperInner = styled.div `
    display: flex;
    max-width: ${pageMaxWidth};
`;
export const CtaWrapper = styled.div `
    align-self: center;
`;
export const Bold = styled.b `
    display: inline-block;
    max-width: 26rem;
    font-size: 2.8rem;
    line-height: 1.5;

    @media (min-width: 500px) {
        max-width: 58rem;
        font-size: inherit;
    }
`;
export const CopyWrapper = styled.div `
    margin: 3.2rem 0 2.4rem;

    > h4 {
        font-size: 1.8rem;
    }

    @media (min-width: 500px) {
        font-size: inherit;
        > h4 {
            font-size: inherit;
        }
    }
`;
export const ConstantineWrapper = styled.div `
    max-width: 42rem;
`;
