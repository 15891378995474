import PropTypes from 'prop-types';
import { iconCheckProgress, iconCheckFail } from 'octo-common-img';
import FormInputPrefix from './FormInputPrefix';
import FormInputHelpText from './FormInputHelpText';
import {
    StyledContainer,
    StyledLabel,
    StyledErrorText,
    StyledIcon,
} from './FormInputMarkup.styled';
import { variants } from './helpers';

const FormInputMarkup = ({
    prefix,
    labelId,
    label,
    inputEl,
    disabled,
    errorHtml,
    helpText,
    isPopulated,
    hasTooltip,
    variant,
    asyncValidating,
    showErrorIcon,
    useMiniHelpText,
    hideLabel,
}) => (
    <StyledContainer hasTooltip={hasTooltip}>
        {prefix && <FormInputPrefix prefix={prefix} variant={variant} />}
        {!hideLabel && (
            <StyledLabel
                htmlFor={labelId}
                isPopulated={isPopulated}
                hasPrefix={!!prefix}
                variant={variant}
                disabled={disabled}
            >
                {label}
            </StyledLabel>
        )}
        {inputEl}
        {errorHtml && showErrorIcon && (
            <StyledIcon>
                <img src={iconCheckFail} alt="" />
            </StyledIcon>
        )}
        <StyledErrorText
            dangerouslySetInnerHTML={{ __html: errorHtml }}
            variant={variant}
            useMiniHelpText={useMiniHelpText}
        />
        {asyncValidating && (
            <StyledIcon>
                <img src={iconCheckProgress} alt="" className="icon-rotate" />
            </StyledIcon>
        )}
        {helpText && (
            <FormInputHelpText
                text={helpText}
                variant={variant}
                useMiniHelpText={useMiniHelpText}
            />
        )}
    </StyledContainer>
);

FormInputMarkup.defaultProps = {
    variant: variants.PRIMARY,
};

FormInputMarkup.propTypes = {
    prefix: PropTypes.string,
    labelId: PropTypes.string,
    label: PropTypes.string,
    inputEl: PropTypes.node,
    errorHtml: PropTypes.string,
    helpText: PropTypes.string,
    isPopulated: PropTypes.bool,
    hasTooltip: PropTypes.bool,
    variant: PropTypes.string,
};

export default FormInputMarkup;
