import { Fragment } from 'react';
import Tooltip from '../../Tooltip/Tooltip';

import { StyledTooltipInputWrapper } from './FormInputWithTooltip.styled';

const FormInputWithTooltip = ({ inputEl, tooltipText }) => (
    <Fragment>
        <StyledTooltipInputWrapper>{inputEl}</StyledTooltipInputWrapper>
        <Tooltip>{tooltipText}</Tooltip>
    </Fragment>
);

export default FormInputWithTooltip;
