import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
const confettiConstantineUrl = 'https://static.octopuscdn.com/constantine/animations/confetti/confetti.json';
export const ConfettiConstatine = () => {
    const container = useRef(null);
    // Mounts the animation
    useEffect(() => {
        const current = container === null || container === void 0 ? void 0 : container.current;
        if (current) {
            lottie.loadAnimation({
                container: current,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                path: confettiConstantineUrl,
            });
        }
        // Unmounts the animation avoiding duplicates
        return () => {
            current && lottie.destroy(current);
        };
    }, []);
    return _jsx("div", { ref: container });
};
