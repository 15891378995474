import styled from 'styled-components';
export const InnerWrapper = styled.div `
    text-align: center;

    > h2 {
        font-size: 2.2rem;
        max-width: 22rem;
        margin: auto;

        @media (min-width: 500px) {
            font-size: 3.6rem;
            max-width: 100%;
        }
    }
`;
export const ParagraphWrapper = styled.div `
    margin: 2.8rem 0;
`;
