import PropTypes from 'prop-types';
import { StyledHelpText } from './FormInputHelpText.styled';

const FormInputHelpText = ({ text, variant, useMiniHelpText }) => (
    <StyledHelpText useMiniHelpText={useMiniHelpText} variant={variant}>
        {text}
    </StyledHelpText>
);

FormInputHelpText.propTypes = {
    text: PropTypes.string,
    variant: PropTypes.string.isRequired,
};

export default FormInputHelpText;
