import { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classname';
import { SVGArrowDownIcon } from 'octo-common-img';
import {
    StyledDropDownContainer,
    StyledDropDownIcon,
    StyledDropDownSelect,
    StyledDropDownLabel,
    StyledDropDownError,
    variants,
} from '../reduxform/DropDown/DropDown.styled';

const option = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    text: PropTypes.string,
});

/**
 * A custom styled drop down component.
 */
export default class DropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLabel: false,
        };
    }

    handleOptionSelected(e) {
        const labelCheck = e.target.value != -1;
        this.setState({ showLabel: labelCheck });

        const value = e.target.value.trim();
        this.props.onSelect(value);
    }

    render() {
        const {
            showError,
            savedValue,
            isDisabled,
            placeholder,
            showLabelText,
            labelText,
            name,
            error,
            variant,
            mini,
            id,
        } = this.props;

        // Dropdown invalid if it doesn't have a value
        const dropDownInvalid =
            showError || savedValue == null || savedValue.length < 1;

        const errorText = error || 'Please choose an option';

        const options = this.props.options.map((option) => (
            <option key={option.value} value={option.value}>
                {option.text}
            </option>
        ));

        if (placeholder) {
            options.unshift(
                <option key={'placeholder'} value={-1}>
                    {placeholder}
                </option>
            );
        }

        const labelClassNames = cn({
            'sr-only': !showLabelText,
        });

        return (
            <div
                className={
                    this.state.showLabel || showLabelText
                        ? 'show-dropdown-labels'
                        : null
                }
            >
                <StyledDropDownContainer>
                    <StyledDropDownLabel
                        htmlFor={id}
                        isPopulated={savedValue}
                        className={labelClassNames}
                    >
                        {labelText}
                    </StyledDropDownLabel>
                    <StyledDropDownSelect
                        name={name}
                        disabled={isDisabled ? 'disabled' : ''}
                        value={savedValue}
                        onChange={(e) => this.handleOptionSelected(e)}
                        id={id}
                        variant={variant}
                        mini={mini}
                        data-testid={`${name}-select`}
                    >
                        {options}
                    </StyledDropDownSelect>
                    <StyledDropDownIcon variant={variant}>
                        <SVGArrowDownIcon />
                    </StyledDropDownIcon>
                    {dropDownInvalid && (
                        <StyledDropDownError>{errorText}</StyledDropDownError>
                    )}
                </StyledDropDownContainer>
            </div>
        );
    }
}

DropDown.defaultProps = {
    variant: variants.PRIMARY,
    id: 'id_dropdown',
};

DropDown.propTypes = {
    // All available dropdown options
    options: PropTypes.arrayOf(option).isRequired,
    // Determines whether the dropdown is disabled or not
    isDisabled: PropTypes.bool,
    // Method to handle selecting a dropdown option
    onSelect: PropTypes.func.isRequired,
    // Text for the messaging shown before someone chooses an option
    placeholder: PropTypes.string,
    // Text for the messaging shown in the select label
    labelText: PropTypes.string,
    // Determines whether the label text is displayed or not
    showLabelText: PropTypes.bool,
    // automatically selects an option - index of option when a number
    // and empty string when no option selected
    savedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string,
    // Allows for passing in a unique ID for a11y purposes
    id: PropTypes.string,
};
