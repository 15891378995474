/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { getInputStyles } from './helpers';

export const StyledContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0.5rem 0 1.5rem;
    overflow: hidden;

    ${({ hasTooltip }) =>
        hasTooltip &&
        css`
            display: inline-block;
        `}
`;

export const StyledLabel = styled.label`
    ${({ theme, variant, hasPrefix, isPopulated, disabled }) => css`
        position: absolute;
        top: 2px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0 auto;
        font-size: ${() => getInputStyles({ theme, variant }).label.size};
        font-weight: ${() => getInputStyles({ theme, variant }).label.weight};
        line-height: 1rem;
        letter-spacing: 0.05rem;
        color: ${() => getInputStyles({ theme, variant }).label.color};
        text-align: left;
        pointer-events: none;
        opacity: 0;
        transition: all 0.2s ease-out;

        ${hasPrefix &&
        css`
            padding-left: 0;
        `}

        ${isPopulated &&
        css`
            opacity: 1;
        `}

        ${disabled &&
        css`
            color: ${theme.colour.grey.base};
        `}
    `}
`;

export const StyledErrorText = styled.div`
    font-weight: 400;
    color: ${({ theme }) => theme.colour.action.error};
    text-align: left;
    ${({ theme, useMiniHelpText }) =>
        useMiniHelpText &&
        css`
            font-size: ${theme.helpText.size};
        `}
`;

export const StyledIcon = styled.div`
    display: block;
    width: 3rem;
    height: 3rem;
    margin: 1.6rem 0;
`;

export const StypedInputWrapper = styled.div`
    position: relative;
`;
