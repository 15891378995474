import { jsx as _jsx } from "react/jsx-runtime";
import { WhichLogos } from 'octo-core';
import { WhichWrapper } from './index.styled';
export const Which = ({ className, testId, }) => {
    const WhichLogoJsx = WhichLogos.map((logo, index) => {
        const maxHeightMultiplier = 1 - 0.04 * (WhichLogos.length - index);
        return (_jsx("img", { src: logo, alt: "", width: '50%', height: `${100 * maxHeightMultiplier}%`, style: {
                transform: `translate(${index * 77}px)`,
                filter: `brightness(${1 - 0.08 * (WhichLogos.length - index)})`,
                margin: `auto 0`,
            } }, logo));
    });
    return (_jsx(WhichWrapper, { "data-testid": testId, className: className, children: WhichLogoJsx }));
};
